import React, { useEffect, useRef, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import './ChipInput.scss';
import url from '../../../utils/services/urls.json'
import { postMethod } from '../../../utils/services/apis';

const ChipInput = (props) => {
  const [files, setFiles] = useState(props?.fileName ? props?.fileName : []);
  const [filesId, setFilesId] = useState([]);
  const inputFile = useRef(null);

  const handleDeleteFile = (fileToDelete) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file?.name !== fileToDelete));
  };
  const handleClick = (e) => {
    inputFile.current.click();
    e.preventDefault();
  };
  const handleFileChange = async (event) => {
    event.preventDefault();
    const newFile = event.target.files[0];
    if (newFile) {
      setFiles((prevFiles) => [...prevFiles, newFile]);
      // localStorage.setItem('files', JSON.stringify([...prevFiles, newFile]));
      let formData = new FormData();
      formData.append("file", newFile);
      await postMethod(url.registerUpload, formData).then((response) => {
        if (response.status === 200 || response.status === 201) {
          setFilesId((prevFiles) => [...prevFiles, response?.data?.id]);
          // localStorage.setItem('estateFile',(prevFiles) => [...prevFiles, response?.data?.id])
        }
      })
    }
  };

  useEffect(() => {
    const fileNames = files.map((file) => file?.name);
    const file = files.map((file) => file);
    props.sendData(fileNames, file, filesId);
  }, [files, filesId]);

  return (
    <FormControl className="formControl chipButton" sx={{ m: 1 }} variant="standard">
      <Autocomplete
        style={{ padding: '0' }}
        clearIcon={false}
        options={[]}
        freeSolo
        multiple
        value={files}
        inputValue=""
        onInputChange={(event, newInputValue) => { }}
        onChange={(event, value) => setFiles(value)}
        renderTags={(value, getTagProps) =>
          value.map((file, index) => (
            <Chip
              key={index}
              deleteIcon={<CloseIcon />}
              className="chip-value"
              // style={{
              //   color: 'var(--primary)',
              //   backgroundColor: 'var(--secondary)',
              //   borderRadius: '4px',
              //   marginBottom: '10px',
              // }}
              label={file?.name}
              onDelete={() => handleDeleteFile(file?.name)}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            // label="Real Estate License (optional)"
            {...params}
            placeholder={files.length === 0 ? "Real State Licence..." : ""}
            InputProps={{
              ...params.InputProps,
              value: '',
              readOnly: true,
              endAdornment: (
                <div>
                  <input type="file" onChange={handleFileChange} ref={inputFile} style={{ display: 'none' }} />
                  <button onClick={handleClick}>
                    <img src={props.icon} alt="icon" />
                  </button>
                </div>
              ),
            }}
            sx={{ caretColor: 'transparent' }}
            variant="standard"
          />
        )}
      />
    </FormControl>
  );
};

export default ChipInput;
