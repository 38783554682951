import React, { useState } from 'react';
import "./CardDetail.scss";
import { Col, Row } from 'react-bootstrap';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Reply from "../../../../assets/images/reply.png";
import Bedroom from "../../../../assets/images/favoriteProperty/bedroom.svg"
import Bathroom from "../../../../assets/images/favoriteProperty/bathroom.svg"
import Parking from "../../../../assets/images/favoriteProperty/parking.svg"
import Clock from "../../../../assets/images/favoriteProperty/clock.svg"
import Home from "../../../../assets/images/favoriteProperty/home.svg"
import Furnishing from "../../../../assets/images/favoriteProperty/furnishing.svg"
import ArrowForward from "../../../../assets/images/listingManager/Arrow-property.svg"
import PropertySize from "../../../../assets/images/listingManager/square_foot.svg";
import AddCardButton from '../AddCardButton/AddCardButton';
import url from "../../../../utils/services/urls.json"
import { postMethodWithToken } from '../../../../utils/services/apis';
import { toast } from 'react-toastify';
import ContinueButton from "../../../Buttons/ContinueButton/ContinueButton";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import prevArrowImage from '../../../../assets/images/searchproperty/backwardarrow.svg';
import nextArrowImage from '../../../../assets/images/searchproperty/forwardarrow.svg';
import Cross from '../../../../assets/images/favoriteProperty/cross.svg';
import Modal from "react-modal";

const CardDetailSection = styled.div`
font-family: Proxima_nova_reg;
// width:900px;
height: auto;
background: white;
padding: 10px;
border: none;
box-shadow: 0px 2px 8px 0px #0000001F;

   @media (max-width:575px) {
   padding: 0px;
  } 

`

const CustomImage = styled.img`
background:url('${props => props?.image}');
height: 182px;
width:100%;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;
cursor: pointer;

@media (min-width:915px) and (max-width: 1199px) {
     height: 162px;
   }

   @media (min-width: 820px) and (max-width: 915px) {
     height: 162px;
   }
      
 @media (min-width:575px) and  (max-width:768px) {
   height: 142px;
  }

   @media (min-width:431px) and  (max-width:575px) {
   height: 320px;
  }

   @media  (min-width:400px) and  (max-width:431px) {
   height: 220px;
  }

   @media (max-width:400px) {
   height: 190px;
  }
`

const CustomImageModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
  
  img {
    max-width: 100%;     
    max-height: 100%;    
    object-fit: contain;  
  }
`;

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const CardDetail = (props) => {
    const moment = require('moment-timezone');
    const navigate = useNavigate()
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = props?.sliderImage?.length;

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const customStylesWithImage = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: screenSize.width <= 800 ? "100vw" : "100vw",
            height: screenSize.height <= 800 ? "100vh" : "100vh",
            maxHeight: "100vh",
            padding: '0px',
            overflow: 'hidden',
            position: "relative",
            backgroundColor: screenSize.width < 1030 ? "#000000" : "transparent",
            border: "none"
        },
    };

    const handleNext = (data, galleryIndex) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === data?.property_images?.length - 1
                ? 0
                : (prevSteps[data?.propertyMaster_id] || 0) + 1
        }));
    };

    const handleBack = (data, galleryIndex) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === 0
                ? data?.property_images?.length - 1
                : (prevSteps[data?.propertyMaster_id] || data?.property_images?.length) - 1
        }))

    };

    const handleStepChange = (data, step) => {
        setActiveStep((prevSteps) => ({
            ...prevSteps,
            [data?.propertyMaster_id]: step
        }));
    };
    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }
    const handleChatDetail = () => {
        navigate("Request")

    }
    const handleRequest = async (propertyId, action) => {
        let managingRequestApi
        if (action === 1) {
            let action = "accept"
            managingRequestApi = `${url.ManagingRequestAcceptReject}?action=${action}`
        } else if (action === 0) {
            let action = "reject"
            managingRequestApi = `${url.ManagingRequestAcceptReject}?action=${action}`
        }
        let body = {
            "propertyId": propertyId
        }
        await postMethodWithToken(managingRequestApi, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                props.acceptValue(true)
                toast.success(response?.data?.success)
            }
        })
    }
    const handleChatWithManager = async (propertyMasterId, bedroom, bathroom, propertyType, price, address, per_month, propertySize, id, profileValue) => {
        let perMonth = per_month === true ? "per_month" : "per_day";
        const receiverPropertyDetail = {
            Id: id,
            PropertyMasterId: propertyMasterId,
            bedroom: bedroom,
            bathroom: bathroom,
            propertyType: propertyType,
            price: price,
            address: address,
            per_month: perMonth,
            propertySize: propertySize

        }
        let body = {
            receiver: id,
            propertyMaster: propertyMasterId,
            content: JSON.stringify(receiverPropertyDetail)
        }
        await postMethodWithToken(url.sendMessage, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                localStorage.setItem('receiverPropertyDetail', response?.data?.content)
                // navigate("/Dashboard/Chats")
                navigate("/Dashboard/Chats", {
                    state: {
                        receiverId: response?.data?.receiver,
                        propertyMasterId: response?.data?.propertyMaster,
                        profileValue: profileValue
                    }
                })
            }
        })
    }

    const getLastWordWithCapital = (sentence) => {
        const words = sentence.trim().split(" ");
        if (words?.length > 1) {
            const lastWord = words[words.length - 1];
            const capitalizedLastWord = lastWord.charAt(0).toUpperCase();
            words[words.length - 1] = capitalizedLastWord + ".";
        }
        return words.join(" ");
    }

    const capitalizeEachWord = (sentence) => {
        const words = sentence.trim().split(" ");
        const capitalizedWords = words.map(word =>
            word.charAt(0).toUpperCase() + word.slice(1)
        );
        return capitalizedWords.join(" ");
    }

    const [detailImage, setDetailImage] = useState("")
    const [imageModal, setImageModal] = useState(false)
    const [indexModal, setIndexModal] = useState("")

    const handleImageModal = (id, index) => {
        setDetailImage(id)
        setImageModal(true)
        setIndexModal(index)
        props.handleImageListModal(true)
    }
    const handleCloseImageModal = () => {
        setImageModal(false)
        props.handleImageListModal(true)
    }

    const CustomPrevArrow = (props) => {
        const { disabled, onClick } = props;
        return (
            <div
                className="custom-image-upload prev-arrows-image"
                onClick={(e) => {
                    e.stopPropagation(); // Stop event propagation
                    if (!disabled && onClick) onClick();
                }}
            >
                <img src={prevArrowImage} alt="Previous" />
            </div>
        );
    };

    const CustomNextArrow = (props) => {
        const { disabled, onClick } = props;
        return (
            <div
                className="custom-image-upload next-arrows-image "
                onClick={(e) => {
                    e.stopPropagation(); // Stop event propagation
                    if (!disabled && onClick) onClick();
                }}
            >
                <img src={nextArrowImage} alt="Next" />
            </div>
        );
    }
    const [currentSlide, setCurrentSlide] = useState(0);
    var settings = {
        infinite: true,
        dots: true,
        speed: 500,
        slidesToScroll: 1,
        slidesToShow: 1,
        autoplaySpeed: 2000,
        beforeChange: (current, next) => setCurrentSlide(next),
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        initialSlide: +detailImage,
        appendDots: (dots) => (
            <div onClick={(e) => e.stopPropagation()}>{dots}</div>
        )
    };


    return (
        <>
            {props?.ListingDetails && props?.ListingDetails.length > 0 && props?.ListingDetails.map((item, index) => {
                return (
                    <div className="card-detail-container" style={{ marginBottom: (props?.ListingDetails && props?.ListingDetails?.length === 1 && screenSize.width <= 360) ? "160px" : (props?.ListingDetails && props?.ListingDetails?.length === 1 && screenSize.width <= 375) ? "100px" : (props?.ListingDetails && props?.ListingDetails?.length === 1 && screenSize.width <= 390) ? "265px" : (props?.ListingDetails && props?.ListingDetails?.length === 1 && screenSize.width <= 412) ? "305px" : (props?.ListingDetails && props?.ListingDetails?.length === 1 && screenSize.width <= 414) ? "290px" : (props?.ListingDetails && props?.ListingDetails?.length === 1 && screenSize.width <= 430) ? "325px" : "" }}>
                        <CardDetailSection>
                            <Row>
                                <Col xs={12} sm={5} md={5} lg={4} xl={4} xxl={4}>
                                    <div>
                                        <SwipeableViews
                                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                            index={activeStep[item?.propertyMaster_id] || 0}
                                            onChangeIndex={(step) => handleStepChange(item, step)}
                                            enableMouseEvents
                                        >
                                            {item?.property_images.map((step, ind) => {
                                                return (
                                                    <>
                                                        <div key={ind}>
                                                            <CustomImage image={step?.media?.file} onClick={() => handleImageModal(ind, index)} />
                                                        </div>

                                                    </>
                                                )
                                            })}
                                        </SwipeableViews>
                                        <MobileStepper
                                            position="static"
                                            variant="text"
                                            nextButton={
                                                <Button size="small" style={{ color: "white" }} onClick={() => handleNext(item, index)}>

                                                    {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                </Button>
                                            }
                                            backButton={
                                                <Button size="small" style={{ color: "white" }} onClick={() => handleBack(item, index)}>
                                                    {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                </Button>
                                            }
                                        />
                                    </div>
                                    <div class="d-flex justify-content-center rent-box">
                                        <div>
                                            <div className="rental-price">${item?.subscription_details?.price}<span style={{ fontSize: "16px", color: "#505050" }} >/{item?.subscription_details?.per_day === true ? "day" : "month"}</span></div>
                                            <div className="rent-heading" >Rent</div>
                                        </div>
                                        <div><hr style={{ border: "1px solid black", height: "40px", marginLeft: "10px", marginTop: "20px" }} /></div>
                                        <div>
                                            <div className="deposit-price" style={{ marginLeft: "10px" }}>${item?.subscription_details?.deposit_amount}<span></span></div>
                                            <div className="rent-heading" style={{ marginLeft: "25px" }}>Deposit</div>
                                        </div>
                                    </div>
                                    <div className="deposit-type">
                                        <div className="d-flex">
                                            <div className="rent-heading" >Rent : </div>
                                            <div className="rental-price">${item?.subscription_details?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{item?.subscription_details?.per_day === true ? "day" : "month"}</span></div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="rent-heading">Deposit : </div>
                                            <div className="deposit-price">$<span>{item?.subscription_details?.deposit_amount}</span></div>

                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12} sm={7} md={7} lg={8} xl={8} xxl={8}>
                                    <div className="ManagerRequest-card-detail" >
                                        <div className="d-flex justify-content-between section-profile" style={{ paddingTop: "10px" }}>
                                            <div className="d-flex">
                                                <div>
                                                    <img src={item?.Property_owner?.[0]?.profilePic} alt="" style={{ width: "55px", height: "55px", border: "1px solid black", borderRadius: "50%" }} />
                                                </div>
                                                <div>
                                                    <div className="d-flex" style={{ paddingLeft: "7px" }}>
                                                        {/* <div className="user-heading">{item?.Property_owner?.[0]?.name?.length > 0 && getUpparCaseName(item?.Property_owner?.[0]?.name)}</div> */}
                                                        <div className="user-heading">{screenSize.width <= 575 ? item?.Property_owner?.[0]?.name?.length > 0 && capitalizeEachWord(getLastWordWithCapital(item?.Property_owner?.[0]?.name)) : item?.Property_owner?.[0]?.name?.length > 0 && getUpparCaseName(item?.Property_owner?.[0]?.name)}</div>
                                                        <div className="user-dot">.</div>
                                                        <div className="property-owner">Property Owner</div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="mobile-type">
                                            <div className="d-flex">
                                                <div className="rent-heading" >Rent : </div>
                                                <div className="rental-price">${item?.subscription_details?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{item?.subscription_details?.per_day === true ? "day" : "month"}</span></div>
                                            </div>
                                            <div className="d-flex" style={{ marginLeft: "10px" }}>
                                                <div className="rent-heading">Deposit : </div>
                                                <div className="deposit-price">$<span>{item?.subscription_details?.deposit_amount}</span></div>

                                            </div>
                                        </div>

                                        <div style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px" }}> {item?.location?.locality}</div>
                                        <Row style={{ marginTop: "10px", borderBottom: "1px solid #B7B7B7", paddingBottom: "5px" }}>
                                            <Col xs={5} sm={5} md={6} lg={4} xl={3} xxl={3} className='d-flex'>
                                                <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                <div className="categroy-heading-type">Bedroom</div>
                                                <div className="category-value">{item?.bedroomCount}</div>
                                            </Col>
                                            <Col xs={7} sm={7} md={6} lg={6} xl={6} xxl={6} className="d-flex">
                                                <div ><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                <div className="categroy-heading-type">Bathroom</div>
                                                <div className="category-value">{item?.propertyType?.name === "Room" ? item?.room_sharing?.name : item?.bathroomCount}</div>
                                            </Col>

                                        </Row>
                                        <div>
                                            <Row >
                                                <Col md={6} lg={4} xl={6} xxl={6}>
                                                    <div className="d-none d-sm-inline">
                                                        <div className="d-flex" style={{ marginTop: "10px" }} >
                                                            <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                            <div className="categroy-heading">Available from</div>
                                                        </div>
                                                        <div className="select-value-heading">{item?.subscription_details?.propertyAvailfrom !== null && moment?.tz(item?.subscription_details?.propertyAvailfrom?.[0], item?.subscription_details?.propertyAvailfrom?.[0]).format("YYYY-MM-DD")}</div>
                                                    </div>
                                                </Col>

                                                {props?.showButton &&
                                                    <Col md={6} lg={6} xl={6} xxl={6}>
                                                        <div className="d-flex align-items-center view-ipaid" style={{ cursor: "pointer", marginLeft: "7px", marginTop: "10px" }}>
                                                            <div className="view-heading" onClick={() => props.onClick(item?.propertyMaster_id, item?.Property_owner?.[0]?.name, item?.subscription_details?.propertyAvailfrom?.[0])}>View Details</div>
                                                            <div onClick={() => props.onClick(item?.propertyMaster_id, item?.Property_owner?.[0]?.name, item?.subscription_details?.propertyAvailfrom?.[0])}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px", marginLeft: "7px" }} /></div>
                                                        </div>
                                                    </Col>

                                                }

                                                <Col xs={5} sm={5} md={6} className="d-flex d-sm-none">
                                                    <div className="d-flex" style={{ marginTop: "10px" }} >
                                                        <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading">Available from</div>
                                                    </div>
                                                </Col>
                                                <Col xs={7} sm={7} md={6} className="d-flex d-sm-none">
                                                    <div className="select-value-heading">{item?.subscription_details?.propertyAvailfrom !== null && moment?.tz(item?.subscription_details?.propertyAvailfrom?.[0], item?.subscription_details?.propertyAvailfrom?.[0]).format("YYYY-MM-DD")}</div>
                                                </Col>

                                            </Row>

                                            {props?.showButton ?
                                                <>
                                                    <div className="d-none d-sm-flex" style={{ marginTop: "17px" }}>
                                                        <AddCardButton clickAccept={() => handleRequest(item?.propertyMaster_id, 1)} clickReject={() => handleRequest(item?.propertyMaster_id, 0)} />
                                                        <div className="chat-card-container" style={{ marginLeft: "10px" }}>
                                                            <ContinueButton text={'Chat'} color={'#2F86D1'} backgroundColor={'#fff'} border={'2px solid #2F86D1'} borderRadius={"4px"} fontWeight={600} width={"120px"} onClick={() => handleChatWithManager(item?.propertyMaster_id, item?.bedroomCount, item?.bathroomCount, item?.propertyType?.name, item?.subscription_details?.price, item?.location?.locality, item?.subscription_details?.per_month, item?.propertySize, item?.Property_owner[0]?.id, item?.Property_owner[0])} />
                                                        </div>
                                                        {/* <div class="d-flex align-items-center"> */}
                                                        <div className="d-flex align-items-center view-container" style={{ cursor: "pointer", marginLeft: "7px" }}>
                                                            <div className="view-heading" onClick={() => props.onClick(item?.propertyMaster_id, item?.Property_owner?.[0]?.name, item?.subscription_details?.propertyAvailfrom?.[0])}>View Details</div>
                                                            <div onClick={() => props.onClick(item?.propertyMaster_id, item?.Property_owner?.[0]?.name, item?.subscription_details?.propertyAvailfrom?.[0])}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px", marginLeft: "7px" }} /></div>
                                                        </div>
                                                        {/* </div> */}
                                                    </div>

                                                    <div className="d-flex d-sm-none justify-content-end">
                                                        <div className="d-flex align-items-center" style={{ cursor: "pointer", marginLeft: "7px" }}>
                                                            <div className="view-heading" onClick={() => props.onClick(item?.propertyMaster_id, item?.Property_owner?.[0]?.name, item?.subscription_details?.propertyAvailfrom?.[0])}>View Details</div>
                                                            <div onClick={() => props.onClick(item?.propertyMaster_id, item?.Property_owner?.[0]?.name, item?.subscription_details?.propertyAvailfrom?.[0])}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px", marginLeft: "7px" }} /></div>
                                                        </div>
                                                    </div>

                                                </>
                                                :
                                                props?.showRequest ?
                                                    <div className="d-flex justify-content-end" style={{ margin: screenSize.width > 575 && "17px 0px 15px 0", fontSize: "12px", }}>
                                                        <div className="d-none d-sm-flex" style={{ fontSize: "16px" }}>
                                                            <ContinueButton text={'Chat'} color={'#2F86D1'} backgroundColor={'#fff'} border={'2px solid #2F86D1'} borderRadius={"4px"} fontWeight={600} width={"160px"} onClick={() => handleChatWithManager(item?.propertyMaster_id, item?.bedroomCount, item?.bathroomCount, item?.propertyType?.name, item?.subscription_details?.price, item?.location?.locality, item?.subscription_details?.per_month, item?.propertySize, item?.Property_owner[0]?.id, item?.Property_owner[0])} />
                                                        </div>
                                                        <div className="d-flex align-items-center align-items-center justify-content-end justify-content-lg-start  mt-2" style={{ cursor: "pointer" }}>
                                                            <div className="view-heading" style={{ cursor: "pointer", margin: "-5px 0px 0 20px" }} onClick={() => props.onClick(item?.propertyMaster_id, item?.Property_owner?.[0]?.name, item?.subscription_details?.propertyAvailfrom?.[0])}>View Details</div>
                                                            <div style={{ cursor: "pointer", marginTop: "-5px", marginLeft: "5px" }} onClick={() => props.onClick(item?.propertyMaster_id, item?.Property_owner?.[0]?.name, item?.subscription_details?.propertyAvailfrom?.[0])}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        </div>
                                                    </div>
                                                    :

                                                    <div className="d-flex align-items-center mt-2" style={{ cursor: "pointer", marginLeft: "15px" }}>
                                                        <div className="view-heading" onClick={() => props.onClick(item?.propertyMaster_id, item?.Property_owner?.[0]?.name, item?.subscription_details?.propertyAvailfrom?.[0])}>View Details</div>
                                                        <div style={{ marginLeft: "5px" }}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} onClick={() => props.onClick(item?.propertyMaster_id, item?.Property_owner?.[0]?.name, item?.subscription_details?.propertyAvailfrom?.[0])} /></div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </CardDetailSection>
                    </div >
                )
            })}

            <Modal isOpen={imageModal} style={customStylesWithImage} overlayClassName="myoverlay">
                <Row>
                    <Col>
                        <div className="modal-upload-image-section" onClick={(e) => {
                            if (screenSize.width > 1030 && !e.target.closest('.slick-dots')) {
                                handleCloseImageModal();
                            }
                        }}>
                            <div style={{ position: "absolute", cursor: 'pointer', top: screenSize.width < 575 ? "30px" : "10px", right: "10px", zIndex: "999" }}>
                                <img
                                    src={Cross}
                                    alt="Remove"
                                    // style={{ cursor: 'pointer' }}
                                    onClick={handleCloseImageModal}
                                />
                            </div>
                            <Slider {...settings} style={{ width: "100%" }}>
                                {props?.ListingDetails && props?.ListingDetails.length > 0 && props?.ListingDetails.map((data, ind) => {
                                    return (
                                        ind === indexModal && data?.property_images.map((step, index) => {
                                            return (
                                                <>
                                                    <div key={index}>
                                                        <CustomImageModal>
                                                            <img src={step?.media?.file} alt={step?.label} />
                                                        </CustomImageModal>
                                                    </div>

                                                </>
                                            )
                                        })
                                    )
                                })}
                            </Slider>
                        </div>
                    </Col>
                </Row>
            </Modal>

        </>

    )
}

export default CardDetail
