import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from "../../../Components/commonComponents/navbar/Navbar";
import Sidebar from "../../../Components/commonComponents/sidebar/Sidebar";
import ContinueButton from "../../../Components/Buttons/ContinueButton/ContinueButton";
import { Col, Row } from 'react-bootstrap';
import url from "../../../utils/services/urls.json"
import "./ChangePlan.scss"
import { getMethodWithToken, postMethodWithToken } from '../../../utils/services/apis';
import { getUserId, navigateLogin } from '../../../utils/services/common';
import SideBarMobile from '../../../Components/commonComponents/SideBarMobile/SideBarMobile';

const ChangePlan = () => {
    const navigate = useNavigate();
    const moment = require('moment-timezone');
    const [subscriptionList, setSubscriptionList] = useState([])
    const [selectedPlanId, setSelectedPlanId] = useState("")
    const [subscriptionPrice, setSubscriptionPrice] = useState("")
    const [monthlyPlan, setMonthlyPlan] = useState(false)

    const subscriptionDetail = localStorage.getItem("subscriptionDetail") !== undefined ? JSON?.parse(localStorage.getItem("subscriptionDetail")) : "";

    useEffect(() => {
        getSubscriptionList()
    }, [])
    const getSubscriptionList = async () => {
        await getMethodWithToken(url.getSubscriptionManager).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setSubscriptionList(response?.data)
            } else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)
            }
        })
    }
    const getPlanNameUpparCase = (planName) => {
        const words = planName.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }
    const handleSubscriptionButton = (e) => {
        // setSelectedPlanId(e.id)
        // localStorage.setItem("subscriptionPrice", e?.pricePerListing?.slice(1))
        // setSubscriptionPrice(e?.pricePerListing?.slice(1))
        // if (e?.name === "Monthly") {
        //     setMonthlyPlan(true)
        // } else {
        //     setMonthlyPlan(false)
        // }

        setSelectedPlanId(e?.subscription_details?.[0]?.id)
        localStorage.setItem("subscriptionPrice", e?.subscription_details?.[0]?.pricePerListing?.slice(1))
        localStorage.setItem("masterSubscrptionManagerId",e?.subscription_details?.[0]?.id)
        setSubscriptionPrice(e?.subscription_details?.[0]?.pricePerListing?.slice(1))
        if (e?.name === "Monthly") {
            setMonthlyPlan(true)
        } else {
            setMonthlyPlan(false)
        }
    }
    const handleChangeSubscription = async () => {
        let body = {
            "user_id": getUserId(),
            "masterSubscrption": selectedPlanId,
            "price": subscriptionPrice,
            "per_month": monthlyPlan
        }
        await postMethodWithToken(url.postSubscription, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                navigate(`/Dashboard/Checkout/Payment`)
            }
        })
    }

    const [mobileModal, setMobileModal] = useState(false)

    const handleGetModal = (value = false) => {
        setMobileModal(value)
    }
    return (
        <div style={{ background: '#f2f2f7', height: 'fit-content' }}>
            <Navbar />
            <div className="d-flex">
                <div className="Sidebar_control side-resposne" >
                    <Sidebar />
                </div>
                <div className="Sidebar_control side-value-component">
                    <div style={{ marginTop: '60px', marginBottom: '5.5vh' }}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
                                <div className="subscription-package-container">
                                    <div className="current-heading">Current Plan</div>
                                    <hr />
                                    <div className="monthly-heading">{subscriptionDetail?.per_month === true ? "Monthly Plan" : "Yearly Plan"}</div>
                                    <div className="d-flex mt-3" >
                                        <div className="price-manager-heading" >${subscriptionDetail?.price}</div>
                                        <div className="month-manager-heading">/ {subscriptionDetail?.per_month === true ? "month" : "year"}</div>
                                    </div>
                                    <Row className="mt-3">
                                        <Col xs={6} sm={4} md={3} lg={3} xl={3} xxl={3}>
                                            <div className="payment-description">Active since</div>
                                            <div className="date-description">{moment.tz(subscriptionDetail?.propertyAvailfrom, subscriptionDetail?.propertyAvailfrom).format("DD MMM YYYY")}</div>
                                        </Col>
                                        <Col xs={6} sm={4} md={3} lg={3} xl={3} xxl={3}>
                                            <div className="payment-description">End Date</div>
                                            <div className="date-description">{moment.tz(subscriptionDetail?.end_date, subscriptionDetail?.end_date).format("DD MMM YYYY")}</div>
                                        </Col>
                                    </Row>
                                    <div className="d-flex justify-content-center change-plan-heading">Change Plan</div>
                                    <div className="d-flex justify-content-center change-plan-description">Select a Subscription Package</div>
                                    <div className="row d-flex justify-content-center">
                                        {subscriptionList && subscriptionList.length > 0 && subscriptionList.map((item, index) => {
                                            return (
                                                <>
                                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 subscription-box" >
                                                        <div className="subscribe-button" style={{ border: item?.subscription_details?.[0]?.id === selectedPlanId ? "2px solid #0075CA" : (item?.name === "Monthly" ? "2px solid #505050" : "2px solid #E3A301") }} onClick={() => handleSubscriptionButton(item)}>
                                                            <div className="amount-term" style={{ color: item?.name === "Monthly" ? "#505050" : "#E3A301" }}>{item?.name}</div>
                                                            <div className="listing-amount" style={{ color: item?.name === "Monthly" ? "#505050" : "#E3A301" }}>{item?.subscription_details?.[0]?.pricePerListing}</div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })}
                                    </div>
                                    {/* <div className="d-flex justify-content-end checkout-btn">
                                        <ContinueButton text="Continue to Checkout" onClick={() => handleChangeSubscription()} color={'#fff'} backgroundColor={(selectedPlanId === "" || selectedPlanId === subscriptionDetail?.masterSubscrption) ? 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)' : 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} disabled={(selectedPlanId === "" || selectedPlanId === subscriptionDetail?.masterSubscrption)} />
                                    </div> */}

                                    <div className="checkout-button">
                                        <ContinueButton text="Continue to Checkout" color={'#fff'} onClick={() => handleChangeSubscription()} backgroundColor={selectedPlanId !== "" ? 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' : 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)'} disabled={selectedPlanId === ""} fontSize={"14px"} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <div className="Sidebar_control side-mobile-responsive">
                <SideBarMobile getModal={(val) => handleGetModal(val)} />
            </div>
        </div>
    )
}

export default ChangePlan
