import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMethodWithToken } from '../../../utils/services/apis';
import url from '../../../utils/services/urls.json';
import CardDetail from '../../../Components/commonComponents/CardDeatil/CardDetal/CardDetail';
import NothingDetail from '../../../Components/commonComponents/CardDeatil/NothingDetail/NothingDetail';
import { navigateLogin } from '../../../utils/services/common';
import { Col, Row } from 'react-bootstrap';


const ManageRequestList = ({handleImageListModal}) => {
    const navigate = useNavigate()
    const [manageList, setManageList] = useState([])
    const [sliderImage, setSliderImage] = useState([])
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    useEffect(() => {
        handleManagingRequestList()
    }, [])

    const handleManagingRequestList = async () => {
        await getMethodWithToken(url.ManagingRequestList).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setManageList(response?.data)
                if (response?.data?.length > 0) {
                    response?.data.map((value, index) => (
                        setSliderImage(value?.property_images)
                    ))
                }
            } else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)
            }

        })
    }
    const handleManagingRequestDetail = async (id, userName) => {
        navigate(`/Dashboard/Manager/Request/Details/${id}/manager_detail`, {
            state: {
                userName: userName
            }
        })
    }
    const getData = (data) => {
        if (data) {
            handleManagingRequestList()
        }
    }
    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ marginBottom: (manageList && manageList.length === 0 && screenSize.width <= 360) ? "100px" : (manageList && manageList.length === 0 && screenSize.width <= 375) ? "100px" : (manageList && manageList.length === 0 && screenSize.width <= 390) ? "100px" : (manageList && manageList.length === 0 && screenSize.width <= 412) ? "100px" : (manageList && manageList.length === 0 && screenSize.width <= 414) ? "100px" : (manageList && manageList.length === 0 && screenSize.width <= 430) ? "100px" : "" }}>
                {manageList && manageList.length > 0 ?
                    <div>
                        <CardDetail ListingDetails={manageList} sliderImage={sliderImage} showButton={true} onClick={handleManagingRequestDetail} acceptValue={getData} handleImageListModal={(val) => handleImageListModal(val)} />
                    </div>
                    :
                    <NothingDetail />
                }
            </Col>
        </Row>
    )
}

export default ManageRequestList
