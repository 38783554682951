import React from 'react';
import Accept from "../../../../assets/images/listingManager/Check.svg"
import Reject from "../../../../assets/images/listingManager/X.svg";
import "./AddCardButton.scss";


const AddCardButton = (props) => {

    return (
        <>  
            <div className='AcceptButton' onClick={() => props.clickAccept()}>
                <img src={Accept} alt="Accept" />
                <p>Accept Request</p>
            </div>
            <div style={{ marginLeft: "7px" }} onClick={() => props.clickReject()}>
                <div className='RejectRequest'>
                    <img src={Reject} alt="Reject" />
                    <p>Reject Request</p>
                </div>
            </div>
        </>
    )
}

export default AddCardButton
