import React from 'react';
import "./NothingDetail.scss"
import { Row, Col } from 'react-bootstrap';

const NothingDetail = () => {
    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                <div className="empty-details">
                    <div className="empty-heading" >Nothing here yet <br /><span className="empty-description">Wait for a request</span></div>
                </div>

            </Col>
        </Row>
    )
}

export default NothingDetail
