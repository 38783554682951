import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom"
import GovHome from '../../assets/images/GovHomeLogo.png'
import banner from '../../assets/images/banner.png'
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import BackButton from "../../Components/Buttons/BackButton/BackButton";
import ContinueButton from "../../Components/Buttons/ContinueButton/ContinueButton";
import "./Home.scss";
import ArrowBack from "../../assets/images/arrow_back-ipad.svg"

const Home = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [checked, setChecked] = useState(false);
    const getParams = (val) => {
        navigate(`/${val}`)
    }
    const checkValue = (e) => {
        setChecked(e.target.checked)
    }
    const handleFederalNavigate = () => {
        window.open(`https://federal.govhomes.theclientdemos.com/federalEmployee`, "_self")
    }
    return (
        <>
            {/* <div className="home">
                <div className="middleContent text-center" >
                    <div className={`${Object.keys(params).length === 0 || window.location.pathname === '/propertyManager' || window.location.pathname === '/federalEmployee' ? 'logoHome' : 'logo'}`}>
                        <img className="logoImg" src={GovHome} alt="" />
                    </div>
                    <div className="bottomContent">
                        <div className="banner">
                            <img className="bannerImg" src={banner} alt="" />
                        </div>
                        {Object.keys(params).length === 0 ?
                            <>
                                <h3 className="d-inline-block ps-3 pe-3">Welcome to Gov Homes!</h3>
                                <p style={{ marginTop: "20px", marginBottom: "26px" }} className="d-block ps-3 pe-3">Gov Homes is designed exclusively for use by Federal employees or members of the armed forces. </p>
                                <div className="bottom">
                                    <ContinueButton onClick={() => getParams("propertyManager")} text={'Property Manager'} color={'#ffffff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} width={'35%'} height={'50px'} />
                                    <ContinueButton onClick={() => handleFederalNavigate()} text={'Federal Employee'} color={'#000'} backgroundColor={'#fff'} border={'1px inset #000'} width={'35%'} height={'50px'} />
                                </div>
                            </> : <></>}
                        {params.value === "propertyManager" ?
                            <>
                                <div className="main-heading d-inline-block ps-3 pe-3" >Property manager</div>
                                <div style={{ margin: '20px auto 26px auto', maxWidth: "575px" }} className="main-description d-block ps-3 pe-3">This registration process is for property managers that are managing listings for users or looking to provide advertising services.</div>
                                <div className="bottom w-100">
                                    <ContinueButton className={'buttonManager'} onClick={() => navigate(`/propertyManager/Registration`)} text={'Continue to Register'} width={'65%'} height={'50px'} color={'#fff'} />
                                    <p onClick={() => navigate(`/propertyManager/Login`)} style={{ color: "#0075CA", fontWeight: "600", fontSize: "20px", cursor: "pointer", borderBottom: "1.5px solid #0075CA", marginTop: "29px" }}>Login if you already have an account</p>
                                </div>
                            </> : <></>}
                        {params.value === "federalEmployee" ?
                            <div >
                                <div className="main-heading d-inline-block ps-3 pe-3" >Before continuing you must verify that you are a Federal Government Employee or member of the Armed Forces</div>
                                <div className="checkbox mt-4 mb-4 d-flex justify-content-center ps-4 pe-4">
                                    <FormCheckInput type="checkbox" name="verifyFed" id="verifyFed" onChange={checkValue} />
                                    <label className="ps-2 lableContent text-start" htmlFor="verifyFed">I herby verify that I am a Federal Government Employee or member of the Armed Forces</label>
                                </div>
                                <div className="bottom w-100">
                                    <ContinueButton backgroundColor={checked ? 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' : 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)'} disabled={!checked} onClick={() => navigate(`/federalEmployee/Registration`)} text={'Continue to Register'} width={'55%'} height={'50px'} color={'#fff'} />
                                    <p onClick={() => navigate(`/federalEmployee/Login`)} style={{ color: "#0075CA", fontWeight: "600", fontSize: "20px", cursor: "pointer", borderBottom: "1.5px solid #0075CA", marginTop: "29px" }}>Login if you already have an account</p>
                                </div>
                            </div>
                            : <></>}
                    </div>
                </div>
            </div> */}

            <div className="home">
                <div className="middleContent" >
                    {Object.keys(params).length !== 0 &&
                        <div>
                            <img className="arrow-back" src={ArrowBack} alt="" onClick={() => navigate(-1)} style={{ cursor: "pointer", marginLeft: "20px" }} />
                        </div>
                    }
                    {/* <div className={`${Object.keys(params).length == 0?'':'logo'}`}> */}
                    <div className={`${Object.keys(params).length == 0 || window.location.pathname == '/propertyManager' || window.location.pathname == '/federalEmployee' ? 'logoHome text-center' : 'logo text-center'}`}>
                        <img className="logoImg" src={GovHome} alt="" />
                    </div>
                    <div className="bottomContent text-center">
                        <div className="banner">
                            <img className="bannerImg" src={banner} alt="" />
                        </div>

                        {Object.keys(params).length == 0 ?
                            <>
                                <h3 className="d-inline-block ps-3 pe-3">Welcome to Gov Homes!</h3>
                                <p style={{ marginTop: "20px", marginBottom: "26px" }} className="d-block ps-3 pe-3">Gov Homes is designed exclusively for use by Federal employees or members of the armed forces. </p>
                                <div className="d-flex justify-content-center home-value">
                                    {/* <ContinueButton onClick={() => getParams("federalEmployee")} text={'Federal Employee'} color={'#fff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} fontSize={"14px"} height={'50px'} />
                                    <ContinueButton onClick={() => handleManagerNavigate()} text={'Property Manager'} color={'#000'} backgroundColor={'#fff'} border={'1px inset #000'} fontSize={"14px"} height={'50px'} /> */}
                                    <ContinueButton className="buttton-value" onClick={() => getParams("propertyManager")} text={'Property Manager'} color={'#ffffff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} width={'35%'} height={'50px'} />
                                    <ContinueButton className="buttton-value" onClick={() => handleFederalNavigate()} text={'Federal Employee'} color={'#000'} backgroundColor={'#fff'} border={'1px inset #000'} width={'35%'} height={'50px'} />

                                </div>

                            </> : <></>}
                        {params.value === "propertyManager" ?
                            <>
                                <div className="main-heading d-inline-block ps-3 pe-3" >Property manager</div>
                                <div style={{ margin: '20px auto 26px auto', maxWidth: "575px" }} className="main-description d-block ps-3 pe-3">This registration process is for property managers that are managing listings for users or looking to provide advertising services.</div>
                                <div className="d-flex justify-content-center home-value w-100">
                                    <ContinueButton className={'buttonManager'} onClick={() => navigate(`/propertyManager/Registration`)} text={'Continue to Register'} width={'65%'} height={'50px'} color={'#fff'} />
                                    <p onClick={() => navigate(`/propertyManager/Login`)} style={{ color: "#0075CA", fontWeight: "600", fontSize: "20px", cursor: "pointer", borderBottom: "1.5px solid #0075CA", marginTop: "29px" }}>Login if you already have an account</p>
                                </div>
                            </> : <></>}
                    </div>
                </div>
            </div>

            {!Object.keys(params).length == 0 &&
                <BackButton className="back" backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"130px"} onClick={() => navigate(-1)} />
            }
        </>
    );
}

export default Home;