import React from 'react'
import styled from 'styled-components';
// import AddCard from "../../../assets/images/add-card.svg";
import PaymentPastTransaction from "../PaymentPastTransaction/PaymentPastTransaction"
import "./PaymentCardDetail.scss"
import { Col, Row } from 'react-bootstrap';

const PaymentCardContainer = styled.div`
font-family: Proxima_nova_reg;
padding-top: 20px;
`

const PaymentCardDetail = () => {
  return (
    <PaymentCardContainer>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={5} xxl={5} >
          <div className="payment-card-seaction">
            <div className="payment-heading">Payment Method</div>
            <hr />
            <div className="payment-card-method">You haven’t saved a method yet</div>

            {/* <div>
              <PaymentPastTransaction />
            </div> */}
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={5} xxl={5}>
          <PaymentPastTransaction />
        </Col>
      </Row>
    </PaymentCardContainer>
  )
}

export default PaymentCardDetail
