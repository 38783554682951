import React, { useEffect, useState } from 'react'
import { getMethodWithToken } from '../../../utils/services/apis';
import url from "../../../utils/services/urls.json"
import { useNavigate } from 'react-router-dom';
import CardDetail from '../../../Components/commonComponents/CardDeatil/CardDetal/CardDetail';
import NothingDetail from '../../../Components/commonComponents/CardDeatil/NothingDetail/NothingDetail';
import { InputAdornment, } from "@material-ui/core";
import TextField from '@mui/material/TextField';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import { MenuItem, Select } from '@mui/material'
import './AssignedListingsDetails.scss'
import { navigateLogin } from '../../../utils/services/common';
import { Col, Row } from 'react-bootstrap';


const AssignDetailContainer = styled.div`
// width:900px;
height: auto;
`

const AssignedListingsDetail = ({handleImageListModal}) => {
    // const navigate = useNavigate()
    // const [assignedListDetail, setAssignedListDetail] = useState([])
    // const [sliderImage, setSliderImage] = useState([])

    // useEffect(() => {
    //     getAssignListDetail()
    // }, [])

    // const getAssignListDetail = async () => {
    //     await getMethodWithToken(url.getAssignList).then((response) => {
    //         if (response.status === 200 || response.status === 201) {
    //             setAssignedListDetail(response?.data)
    //             if (response?.data?.length > 0) {
    //                 response?.data.map((value, index) => (
    //                     setSliderImage(value?.property_images)
    //                 ))
    //             }
    //         }
    //     })
    // }
    // const handleViewDetail = (id, userName, availableDate) => {
    //     navigate(`Assign/Detail/${id}`, {
    //         state: {
    //             userName: userName,
    //             availableDate: availableDate
    //         }
    //     })
    // }
    const navigate = useNavigate()
    const [sliderImage, setSliderImage] = useState([])
    const [assignedListDetail, setAssignedListDetail] = useState([])
    const [searchInput, setSearchInput] = useState("")
    const [searchClick, setSearchClick] = useState("")
    const [sortValue, setSortValue] = useState("")
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value)
    }
    const handleSortChange = (event) => {
        setSortValue(event.target.value)
    }
    const handleSearch = () => {
        setSearchClick(searchInput)
    }
    useEffect(() => {
        getAssignListDetail()
    }, [searchClick])
    const getAssignListDetail = async () => {
        await getMethodWithToken(url.getAssignList + "?search=" + searchInput).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setAssignedListDetail(response?.data)
                if (response?.data?.length > 0) {
                    response?.data.map((value, index) => (
                        setSliderImage(value?.property_images)
                    ))
                }
            }
            else if (response?.status === 401) {
                navigateLogin()
                navigate(`/propertyManager/Login`)
            }
        })
    }
    const handleViewDetail = (id) => {
        navigate(`Assign/Detail/${id}`)
    }


    return (
        <div className="assign-list-container" style={{ paddingTop: assignedListDetail && assignedListDetail.length > 0 && "20px", marginBottom: (assignedListDetail && assignedListDetail?.length === 0 && screenSize.width <= 360) ? "140px" : (assignedListDetail && assignedListDetail?.length === 0 && screenSize.width <= 375) ? "100px" : (assignedListDetail && assignedListDetail?.length === 0 && screenSize.width <= 390) ? "160px" : (assignedListDetail && assignedListDetail?.length === 0 && screenSize.width <= 412) ? "160px" : (assignedListDetail && assignedListDetail?.length === 0 && screenSize.width <= 414) ? "160px" : (assignedListDetail && assignedListDetail?.length === 0 && screenSize.width <= 430) ? "150px" : "" }}>
            <AssignDetailContainer>
                <Row>
                    {assignedListDetail && assignedListDetail.length > 0 &&
                        <Col xs={12} sm={12} md={10} lg={8} xl={6} xxl={6}>
                            <div>
                                <TextField
                                    fullWidth
                                    name="search"
                                    id="outlined-basic"
                                    label="Search by name..."
                                    variant="outlined"
                                    value={searchInput}
                                    onChange={(e) => handleSearchInputChange(e)}
                                    style={{ background: "#ffffff" }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <div className="search-btn" onClick={() => handleSearch()}>
                                                        <div>
                                                            <SearchIcon style={{ color: "white", width: "25px", height: "25px" }} />
                                                        </div>
                                                        <div className="d-none d-sm-flex" style={{ color: "white", fontSize: "20px", fontWeight: 500, paddingLeft: "7px" }} >Search</div>
                                                    </div>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                        </Col>

                    }

                    <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        {assignedListDetail && assignedListDetail.length > 0 ?
                            <div>
                                <CardDetail ListingDetails={assignedListDetail} sliderImage={sliderImage} showRequest={true} onClick={handleViewDetail} handleImageListModal={(val) => handleImageListModal(val)} />
                            </div>
                            :
                            <NothingDetail />
                        }
                    </Col>
                </Row>
            </AssignDetailContainer>
        </div>


    )
}
export default AssignedListingsDetail